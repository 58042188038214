<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_label_print_control_add" />
      <!-- ラベル印刷制御登録画面 -->
      <v-container fluid>
        <v-row class="search-row">
          <v-form ref="defaultForm" class="d-flex">
            <!-- 営業所CD -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="defaultForm.officeSelected"
                :label="$t('label.lbl_office_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 取引先CD -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="defaultForm.clientSelected"
                :items="defaultForm.clientList"
                :label="$t('label.lbl_client_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="clientHint()"
                :rules="[rules.supplierRequired]"
                :readonly="defaultForm.isClientDisabledFlg"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-form>
        </v-row>
        <v-row class="justify-space-between mb-0 mt-0">
          <v-col cols="3" class="text-left">
            <div>
              <!-- 戻るボタン -->
              <v-btn
                color="primary"
                id="btn-content-confirm"
                class="other-btn ml-0"
                @click="backBtnDialog()"
                >{{ $t("btn.btn_back") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <!-- タイプ選択 -->
        <v-row class="mt-0 pt-0" justify="center">
          <v-col :cols="2" class="justify-center pt-0">
            <v-form ref="selectTypeForm">
              <v-container>
                <v-row class="justify-center">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="border: 1px solid #bdbdbd; background-color: #effad9; font-weight: bold"
                  >
                    {{ $t("label.lbl_type_select") }}
                  </v-col>
                </v-row>
                <v-row class="justify-center mb-2">
                  <v-btn color="primary" id="btn-content-confirm" class="" @click="decisionBtn()">{{
                    $t("btn.btn_decision")
                  }}</v-btn>
                </v-row>
                <div
                  class="cards-container"
                  @mouseenter="showScrollbar"
                  @mouseleave="hideScrollbar"
                  style="height: calc(100vh - 279px)"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        dense
                        outlined
                        hover
                        class="my-1"
                        v-for="item in typeSelectForm.bcrTypeList"
                        v-bind:key="item.bcrType"
                        :class="{ selected: item.codeName === typeSelectForm.selectedType }"
                        @click="bcrTypeClick(item.codeName)"
                      >
                        <v-card-title>{{ item.codeName }}</v-card-title>
                        <v-card-text v-if="item.remarks">
                          {{ item.remarks }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-form>
          </v-col>
          <!-- 項目設定 -->
          <v-col :cols="5" class="justify-center pt-0">
            <v-form ref="itemSettingsForm">
              <v-container>
                <v-row class="justify-center mb-2">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="
                      border: 1px solid #bdbdbd;
                      background-color: rgb(221, 235, 247);
                      font-weight: bold;
                    "
                  >
                    {{ $t("label.lbl_item_settings") }}
                  </v-col>
                </v-row>
                <v-row class="d-flex search-row">
                  <!-- 選択タイプ -->
                  <div class="pt-1">
                    <v-text-field
                      dense
                      readonly
                      v-model="itemSettings.selectedTypeName"
                      :label="$t('label.lbl_selected_type')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputRequired]"
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer mr-auto">*</span>
                  <!-- クリアボタン -->
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class="mr-2"
                    @click="clearBtnDialog"
                    >{{ $t("btn.btn_searchDataClear") }}</v-btn
                  >
                  <!-- 登録ボタン -->
                  <v-btn color="primary" id="btn-content-confirm" class="" @click="addBtnDialog">{{
                    $t("btn.btn_insert")
                  }}</v-btn>
                </v-row>
                <v-row>
                  <v-container fluid class="pa-0">
                    <v-data-table
                      id="listData"
                      fixed-header
                      :headers="itemSettings.itemSettingsListHeaders"
                      :items="itemSettings.itemSettingsList"
                      disable-filtering
                      disable-sort
                      disable-pagination
                      :hide-default-footer="true"
                      height="calc(100vh - 309px)"
                    >
                      <!-- 印刷項目 -->
                      <template v-slot:[`item.name`]="{ item }">
                        <div class="text-start">{{ item.name }}</div>
                      </template>
                      <!-- 有無 -->
                      <template v-slot:[`item.item`]="{ item }">
                        <v-autocomplete
                          dense
                          v-model="item.item"
                          :items="item.yesNoList"
                          item-text="remarks"
                          item-value="item03"
                          persistent-hint
                          :rules="item.yesNoList.length > 0 ? [rules.inputRequired] : []"
                        >
                          <template slot="item" slot-scope="data">
                            <span class="test">
                              {{ data.item.remarks }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
          <!-- 登録済ラベル -->
          <v-col :cols="5" class="justify-center pt-0">
            <v-container>
              <v-row class="justify-center mb-2">
                <v-col
                  class="text-center pa-2 mb-2"
                  style="
                    border: 1px solid #bdbdbd;
                    background-color: rgb(217, 217, 217);
                    font-weight: bold;
                  "
                >
                  {{ $t("label.lbl_registered_label") }}
                </v-col>
              </v-row>
              <v-row class="d-flex search-row">
                <div class="pa-0 align-self-end mr-auto">
                  {{ $t("label.lbl_registration_status") }}
                </div>
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="btn-content-confirm"
                  class="mr-2"
                  @click="searchBtn(false, true)"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!-- 修正ボタン -->
                <v-btn color="primary" id="btn-content-confirm" class="mr-2" @click="editBtn">{{
                  $t("btn.btn_fix")
                }}</v-btn>
                <!-- 削除ボタン -->
                <v-btn color="primary" id="btn-content-confirm" @click="delBtnDialog">{{
                  $t("btn.btn_delete")
                }}</v-btn>
              </v-row>
              <v-row>
                <v-container fluid class="pa-0 pt-4">
                  <v-data-table
                    id="listData"
                    fixed-header
                    :headers="registeredLabelListHeaders"
                    :items="registeredLabelList"
                    disable-filtering
                    disable-sort
                    disable-pagination
                    :hide-default-footer="true"
                    height="calc(100vh - 309px)"
                  >
                    <template v-slot:[`item.check`]="{ item }">
                      <input
                        v-if="item.procCd == 100 || (item.procCd == 400 && item.listCd == '01')"
                        type="checkbox"
                        v-model="item.check"
                        style="transform: scale(2)"
                      />
                    </template>
                  </v-data-table>
                </v-container>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_INI_006,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    openMenu: false,
    // WH工程
    whProcList: ["100", "200", "300", "310", "400", "500"],
    // WH用のテンプレート
    defualtWhList: [],
    // DP用の帳票CD
    defualtDpList: [],
    // DP用のテンプレート
    defualtDpTemplateList: [],
    defaultForm: {
      officeSelected: null,
      officeList: [],
      clientSelected: null,
      clientList: [],
      isClientDisabledFlg: false,
    },
    typeSelectForm: {
      // バーコード種類
      bcrTypeList: [],
      selectedType: null,
    },
    itemSettings: {
      selectedType: null,
      selectedTypeName: "",
      selectedData: [],
      // バーコード項目
      selectItemList: [],
      // 設定項目
      itemSettingsList: [],
      itemSettingsListHeaders: [
        // 順番
        {
          text: i18n.tc("label.lbl_order"),
          value: "no",
          width: "2%",
          align: "center",
        },
        // 印刷項目
        {
          text: i18n.tc("label.lbl_print_column"),
          value: "name",
          width: "12%",
          align: "center",
        },
        // 有無
        {
          text: i18n.tc("label.lbl_availability"),
          value: "item",
          width: "4%",
          align: "center",
        },
      ],
    },
    registeredLabelList: [],
    registeredLabelListHeaders: [
      // 選択
      {
        text: i18n.tc("label.lbl_select"),
        value: "check",
        width: "2%",
        align: "center",
      },
      // 工程
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "procCd",
        width: "3%",
        align: "center",
      },
      // ラベルCD
      {
        text: i18n.tc("label.lbl_label_cd"),
        value: "listCd",
        width: "3%",
        align: "center",
      },
      // ラベル名
      {
        text: i18n.tc("label.lbl_label_name"),
        value: "listName",
        width: "12%",
        align: "left",
      },
    ],
  }),
  methods: {
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    showScrollbar(event) {
      event.target.style.overflowY = "auto";
    },
    hideScrollbar(event) {
      event.target.style.overflowY = "hidden";
    },
    // 初期化
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 項目設定初期化
      this.itemSettingsInit();
      // 営業所
      const officeList = getParameter.getOffice();
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // バーコード種類
      const bcrTypeList = getParameter.getBcrType({ physicalDb1: "m902" });
      // 有無区分
      const selectItemList = getParameter.getBcrItem({ physicalDb1: "m902" });
      Promise.all([officeList, clientList, bcrTypeList, selectItemList])
        .then((result) => {
          // 画面の初期値を設定します。
          // 営業所
          this.defaultForm.officeList = result[0];
          this.defaultForm.officeSelected = this.defaultForm.officeList[0].text;
          // 取引先
          this.defaultForm.clientList = result[1];
          // バーコード種類
          this.typeSelectForm.bcrTypeList = result[2];
          // 有無区分
          this.itemSettings.selectItemList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先
    clientHint() {
      const client = this.defaultForm.clientList.find(
        (data) => data.value == this.defaultForm.clientSelected
      );
      return client ? client.name : "";
    },
    // タイプ選択
    bcrTypeClick(bcrType) {
      this.typeSelectForm.selectedType = bcrType;
    },
    // 項目設定初期化
    itemSettingsInit() {
      // バリデーションチェックをリセット
      this.$refs.itemSettingsForm.resetValidation();
      for (let i = 1; i <= 20; i++) {
        this.$set(this.itemSettings.itemSettingsList, i - 1, {
          no: i,
          name: "",
          item: "",
          yesNoList: [],
        });
      }
      // タイプ選択情報
      this.itemSettings.selectedType = null;
      this.itemSettings.selectedTypeName = "";
      // 取引先
      this.defaultForm.isClientDisabledFlg = false;
      // 選択データ
      this.itemSettings.selectedData = [];
    },
    /**
     * 戻るダイアログ
     */
    backBtnDialog() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.backBtn;
    },
    /**
     * 戻る
     */
    backBtn() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_INI_004,
      });
    },
    /**
     * 基本項目設定
     */
    defualtItemSetting(procCd) {
      // 項目設定
      const groupValues = [...new Set(this.itemSettings.selectItemList.map((item) => item.item01))];
      if (procCd == 400) {
        for (let i = 0; i < groupValues.length; i++) {
          if (groupValues[i] == "print_timing") {
            groupValues.splice(i, 1);
            i--;
          }
        }
      }
      const groupedResults = groupValues.reduce((groups, groupValue) => {
        groups.push(
          this.itemSettings.selectItemList.filter((group) => group.item01 === groupValue)
        );
        return groups;
      }, []);
      let i = 0;
      groupedResults.forEach((grouped) => {
        this.itemSettings.itemSettingsList[i].yesNoList = grouped;
        this.itemSettings.itemSettingsList[i].name = grouped[0].codeName;
        this.itemSettings.itemSettingsList[i].item01 = grouped[0].item01;
        this.itemSettings.itemSettingsList[i].item = grouped[0].item03;
        i++;
      });
    },
    /**
     * 決定ボタン
     */
    decisionBtn() {
      if (this.$refs.defaultForm.validate()) {
        this.loadingCounter = 1;
        const item = this.typeSelectForm.bcrTypeList.find(
          (data) => data.codeName === this.typeSelectForm.selectedType
        );
        if (item) {
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択情報
          this.itemSettings.selectedType = item;
          this.itemSettings.selectedTypeName = item.codeName;
          // 基本項目設定
          this.defualtItemSetting();

          // デフォルト値
          const dataCnt = this.itemSettings.selectedData.length;
          if (dataCnt == 0) {
            // テンプレート取得
            this.getTemplate()
              .then(() => {
                const procList = getParameter.getProc();
                Promise.all([procList]).then((result) => {
                  this.itemSettings.selectedData = [...this.defualtWhList];
                  if (this.defualtDpList.length > 0) {
                    result[0].forEach((proc) => {
                      if (!this.whProcList.includes(proc.procCd)) {
                        this.defualtDpList.forEach((dpData) => {
                          dpData.procCd = proc.procCd;
                          this.itemSettings.selectedData.push(Object.assign({}, dpData));
                        });
                      }
                    });
                  }

                  // テンプレートが存在する場合、テンプレートを追加
                  if (this.defualtDpTemplateList.length > 0) {
                    this.defualtDpTemplateList.forEach((template) => {
                      this.itemSettings.selectedData.push(Object.assign({}, template));
                    });
                  }

                  this.itemSettings.selectedData.forEach((data) => {
                    delete data.updateDatetime;
                  });

                  // 登録済みラベルのチェックを外す
                  this.registeredLabelList.forEach((item) => {
                    item.check = false;
                  });
                });
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              });
          }
        } else {
          this.infoDialog.message = i18n.tc("check.chk_select");
          this.infoDialog.title = i18n.tc("btn.btn_ok");
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        }
        this.loadingCounter = 0;
      }
    },
    /**
     * クリアダイアログ
     */
    clearBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_searchDataClear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearBtn;
    },
    /**
     * クリア
     */
    clearBtn() {
      // 項目設定初期化
      this.itemSettingsInit();
    },
    /**
     * 登録ダイアログ
     */
    addBtnDialog() {
      if (this.$refs.defaultForm.validate() && this.$refs.itemSettingsForm.validate()) {
        this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
        this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("label.lbl_regist"),
        ]);
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.addBtn;
      }
    },
    /**
     * 登録
     */
    addBtn() {
      this.loadingCounter = 1;
      // 印刷発行フラグ
      const printFlgVal = this.itemSettings.itemSettingsList.find(
        (data) => data.item01 == "print_flg"
      );
      // 数量印刷有無
      const stockPrntFlgVal = this.itemSettings.itemSettingsList.find(
        (data) => data.item01 == "stock_prnt_flg"
      );
      // ロット印刷有無
      const lotPrntFlgVal = this.itemSettings.itemSettingsList.find(
        (data) => data.item01 == "lot_prnt_flg"
      );
      // 発行タイミング
      const printTimingVal = this.itemSettings.itemSettingsList.find(
        (data) => data.item01 == "print_timing"
      );
      this.itemSettings.selectedData.forEach((data) => {
        // 営業所SID
        data.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        data.clientSid = this.defaultForm.clientSelected;

        // DP用のテンプレートの場合、処理なし
        const dpTemplate = this.defualtDpTemplateList.find(
          (template) => template.procCd == data.procCd && template.listCd == data.listCd
        );
        if (dpTemplate) {
          return;
        }

        // 印刷発行フラグ
        if (data.procCd == "100" && data.listCd == "01") {
          data.printFlg = printFlgVal.item;
        }
        // 数量印刷有無
        data.stockPrntFlg = stockPrntFlgVal.item;
        // ロット印刷有無
        data.lotPrntFlg = lotPrntFlgVal.item;
        // 発行タイミング
        if (data.procCd == 400 && data.listCd == "02") {
          data.printTiming = printTimingVal.item;
          // 出庫ラベル発行タイミングが「3選択指示完了」の場合、数量に0をセット
          // 以外は、数量に1をセット
          if (printTimingVal.item == "3") {
            data.stockPrntFlg = "0";
          } else {
            data.stockPrntFlg = "1";
          }
        }
        // 計算処理
        if (data.procCd == 400 && data.listCd == "01") {
          if (this.itemSettings.selectedType == 400) {
            // 印刷発行フラグ
            data.printFlg = printFlgVal.item;
          } else {
            // 印刷=1印刷する＆数量=1印刷する場合は、1をセット
            // 以外は、0をセット
            if (printFlgVal.item == "1" && stockPrntFlgVal.item == "1") {
              data.printFlg = "1";
            } else {
              data.printFlg = "0";
            }
          }
        }
      });
      const upsertData = this.apiProcPrintCtrlUpsert(
        { ProcPrintCtrlList: [...this.itemSettings.selectedData] },
        false
      );
      Promise.all([upsertData])
        .then(() => {
          this.searchBtn(true, false);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * 検索
     */
    searchBtn(refreshFlg, searchFilterFlg = false) {
      // 営業所、取引先のバリデーション
      if (refreshFlg || this.$refs.defaultForm.validate()) {
        let conditions = {};
        let continueFlg = true;
        if (refreshFlg) {
          if (Object.keys(this.searchFilter).length != 0) {
            conditions = this.searchFilter;
          } else {
            // 検索痕跡が存在しない場合、処理しない
            continueFlg = false;
          }
        } else {
          conditions = { clientSid: this.defaultForm.clientSelected };
        }
        if (continueFlg) {
          this.loadingCounter = 1;
          const qrTmpList = this.apiProcPrintCtrlSearch(conditions, searchFilterFlg);
          Promise.all([qrTmpList])
            .then((result) => {
              const list = [];
              result[0].forEach((row) => {
                list.push({
                  check: false,
                  officeSid: row.officeSid,
                  clientSid: row.clientSid,
                  procCd: row.procCd,
                  listCd: row.listCd,
                  listName: row.listName,
                  printFlg: row.printFlg,
                  itemCdPrntFlg: row.itemCdPrntFlg,
                  stockPrntFlg: row.stockPrntFlg,
                  lotPrntFlg: row.lotPrntFlg,
                  printDatePrintFlg: row.printDatePrintFlg,
                  printTiming: row.printTiming,
                  outInstSidPrntFlg: row.outInstSidPrntFlg,
                  itemManageNoPrntFlg: row.itemManageNoPrntFlg,
                  yobi01: row.yobi01,
                  yobi02: row.yobi02,
                  yobi03: row.yobi03,
                  conditionColumn: row.conditionColumn,
                  conditionSymbol: row.conditionSymbol,
                  conditionDetails: row.conditionDetails,
                  deleteFlg: row.deleteFlg,
                  updateDatetime: row.updateDatetime,
                });
              });
              this.registeredLabelList = list;
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              this.loadingCounter = 0;
            });
        }
      }
    },
    /**
     * 修正
     */
    editBtn() {
      const checkItem = this.registeredLabelList.filter((item) => item.check === true);
      if (checkItem.length === 1) {
        // 項目設定初期化
        this.itemSettingsInit();
        // 基本項目設定
        this.defualtItemSetting(checkItem[0].procCd);
        // 取引先
        this.defaultForm.isClientDisabledFlg = true;
        // テンプレート取得
        this.getTemplate().then(() => {
          // 印刷発行フラグ、数量印刷有無、ロット印刷有無
          let defualtItem = null;
          if (checkItem[0].procCd == 400) {
            defualtItem = this.registeredLabelList.find(
              (data) => data.procCd == checkItem[0].procCd && data.listCd == checkItem[0].listCd
            );
          } else {
            defualtItem = this.registeredLabelList.find((data) => data.procCd == 100);
          }
          // 発行タイミング
          const printTimingItem = this.registeredLabelList.find(
            (data) => data.procCd == 400 && data.listCd == "02"
          );
          this.itemSettings.itemSettingsList.forEach((data, index) => {
            if (data.item01 == "print_flg") {
              // 印刷発行フラグ
              this.itemSettings.itemSettingsList[index].item = defualtItem.printFlg;
            } else if (data.item01 == "stock_prnt_flg") {
              // 数量印刷有無
              this.itemSettings.itemSettingsList[index].item = defualtItem.stockPrntFlg;
            } else if (data.item01 == "lot_prnt_flg") {
              // ロット印刷有無
              this.itemSettings.itemSettingsList[index].item = defualtItem.lotPrntFlg;
            } else if (data.item01 == "print_timing") {
              // 発行タイミング
              this.itemSettings.itemSettingsList[index].item = printTimingItem.printTiming;
            }
          });
          // 選択タイプ
          this.itemSettings.selectedType = checkItem[0].procCd;
          this.itemSettings.selectedTypeName = checkItem[0].listName;
          if (checkItem[0].procCd == 100) {
            const filterList = this.registeredLabelList.filter(
              (data) => data.procCd != 400 || data.listCd != "01"
            );
            this.itemSettings.selectedData = JSON.parse(JSON.stringify(filterList));
          } else if (checkItem[0].procCd == 400 && checkItem[0].listCd == "01") {
            const filterList = this.registeredLabelList.filter(
              (data) => data.procCd == 400 && data.listCd == "01"
            );
            this.itemSettings.selectedData = JSON.parse(JSON.stringify(filterList));
          }
          this.itemSettings.selectedData.forEach((item) => {
            delete item.check;
          });
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        });
      } else if (checkItem.length > 1) {
        this.infoDialog.message = messsageUtil.getMessage("P-TMP-903_003_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除ダイアログ
     */
    delBtnDialog() {
      const checkItem = this.registeredLabelList.filter((item) => item.check === true);
      if (checkItem.length > 0) {
        const filterList = checkItem.filter((data) => data.procCd == 400 && data.listCd == "01");
        if (checkItem.length == 1 && filterList.length > 0) {
          this.infoDialog.message = messsageUtil.getMessage("P-INI-006_001_E");
          this.infoDialog.title = i18n.tc("btn.btn_ok");
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        } else {
          this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
          this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
            i18n.tc("label.lbl_delete"),
          ]);
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.ConfirmDialog.okAction = this.delBtn;
        }
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除
     */
    delBtn() {
      const delList = [...this.registeredLabelList];
      delList.forEach((item) => {
        delete item.check;
        // 削除フラグ
        item.deleteFlg = "1";
      });
      this.loadingCounter = 1;
      const delResult = this.apiProcPrintCtrlUpsert({ ProcPrintCtrlList: delList }, true);
      Promise.all([delResult])
        .then(() => {
          this.searchBtn(true, false);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * API：工程別印刷制御の取得
     */
    apiProcPrintCtrlSearch(conditions, searchFilterFlg = false) {
      const config = this.$httpClient.createGetApiRequestConfig();
      Object.assign(config.params, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.INI_PROC_PRINT_CTRL_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (searchFilterFlg) {
                // 検索条件
                this.searchFilter = conditions;
              }
              resolve(jsonData.resIdv.ProcPrintCtrlList);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
    /**
     * API：工程別印刷制御の登録・修正・削除
     */
    apiProcPrintCtrlUpsert(conditions, deleteFlg, messageIgnoreFlg = false) {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_006; // 画面ID
      Object.assign(body.reqIdv, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.INI_PROC_PRINT_CTRL_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (!messageIgnoreFlg) {
                if (!deleteFlg) {
                  // 登録
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                } else {
                  // 削除
                  this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
                }
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
    /**
     * テンプレート初期化
     */
    initTemplate() {
      this.defualtWhList = [];
      this.defualtDpList = [];
      this.defualtDpTemplateList = [];
    },
    /**
     * テンプレート取得
     */
    getTemplate() {
      // テンプレート初期化
      this.initTemplate();
      const client = this.defaultForm.clientList.find(
        (data) => data.value == this.defaultForm.clientSelected
      );
      const getOfficeModuleInfo = getParameter.getOfficeModuleInfo({
        officeCd: sessionStorage.getItem("office_cd"),
        clientCd: client.clientCd,
      });
      return Promise.all([getOfficeModuleInfo])
        .then((result) => {
          let searchParam = { searchOfficeSid: [] };
          result[0].forEach((module) => {
            if (module.moduleCd == "WH") {
              searchParam.searchOfficeSid.push("WH00000001");
            } else if (module.moduleCd == "DP") {
              searchParam.searchOfficeSid.push(...["DP00000001", "DPXXXXXXXX"]);
            }
          });
          const defualtDataList = this.apiProcPrintCtrlSearch(searchParam);
          return Promise.all([defualtDataList]);
        })
        .then((result) => {
          this.defualtWhList = result[0].filter((data) => data.officeSid == "WH00000001");
          this.defualtDpList = result[0].filter((data) => data.officeSid == "DP00000001");
          this.defualtDpTemplateList = result[0].filter((data) => data.officeSid == "DPXXXXXXXX");
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        });
    },
  },
  computed: {},
  watch: {
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.cards-container {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.selected {
  border-top: groove 3px #00bfff; /* 上 */
  border-bottom: groove 3px #00bfff; /* 下 */
  border-left: groove 3px #00bfff; /* 左 */
  border-right: groove 3px #00bfff; /* 右 */
}
</style>
